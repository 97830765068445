.progress.section-end > .progress-bar.bg-light {
  background-color:var(--bs-secondary-bg) !important;
}
.progress.section-end:not(.last) > .progress-bar.bg-primary {
  border-right:2px solid #fff;
}
.progress.section-end:not(.last) > .progress-bar.bg-light {
  border-right:2px solid #fff;
}

.tooltip-inner {
  text-align:left;
}

.feature-icon-small {
  width: 3rem;
  height: 3rem;
}

.form-signin {
  max-width: 400px;
  padding: 15px;
}
.form-signin .form-floating:focus-within {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin.signup-mode input[type="email"] {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.info-bubble:hover:before {
  content:'\f430'
}

.btn-icon:before {
  transform:scale(1.5);
}

.grammar-box {
  width:220px;
  min-height:120px;
  margin-bottom:30px;
  zoom:0.9;
}

.grammar-operator {
  width:30px;
  padding:0;
  margin-bottom:30px;
  zoom:0.9;
}

.chinese-dot {
  color:#aaa;
}

.table th {
  padding:10px 20px;
  width:1%;
  white-space:nowrap;
}
.table td {
  padding:10px 20px;
  width:1%;
  white-space:nowrap;
}
.table {
  width:1%;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
.pop {
  animation: .3s pop;
}

.spinner-container {
  transition:opacity 1s;
}

a.nav-link {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
a.nav-link:focus,
a.nav-link:focus-visible,
a.nav-link:hover,
a.nav-link:active {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.btn-action, .nav-link { transition: all .2s ease-in-out; }
.btn-action:hover, .nav-link:hover { transform: scale(1.1); }

.panel {
  box-shadow: 4px 4px 0 0 #eee;
  border-radius: var(--bs-border-radius-lg) !important;
  background-color: rgba(var(--bs-light-rgb),1) !important
}
.panel-inset {
  box-shadow: inset 2px 2px 0 0 #eee;
  border-radius: var(--bs-border-radius-lg) !important;
  background-color: rgba(var(--bs-white-rgb),1) !important
}
