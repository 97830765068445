@import '../node_modules/bootstrap/scss/functions';

// file is automatically compiled during FE build step

$min-contrast-ratio: 1.2;

$danger: #b71616; // red
$primary: #f13332; // orange
$warning: #ffba18; // yellow
$success: #29d6b4; // green
$info: #4A90E2; // blue
$secondary:#4a4947; // gray med
$medium:#7a7977; // gray med
$light:#f9f9f8; // gray light
$dark:#3F3837; // gray dark

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    darken($success, 7%),
  "warning":    $warning,
  "danger":     $danger,
  "info":       $info,
  "light":      $light,
  "medium":     $medium,
  "dark":       $dark
) !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  25: $spacer * .75,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;


@import "../node_modules/bootstrap/scss/bootstrap";
